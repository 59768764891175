
  import { defineComponent, onMounted, ref, computed, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Multiselect from '@vueform/multiselect';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'user-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<string>('');
      const selectedOffice = ref<Array<string>>([]);
      const selectedClient = ref<number>(0);
      const selectedRole = ref<number>(0);
      const schema = Yup.object().required('selectedOffice');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => selectedClient.value,
        async () => {
          if (selectedClient.value) {
            selectedOffice.value = [];
            await store.dispatch(
              Actions.GET_SHIPMENT_GW_OFFICES,
              selectedClient.value
            );
          }
        }
      );

      const goBack = () => {
        router.back();
      };

      const userSchema = Yup.object().shape({
        mobile: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Mobile'),
        fullname: Yup.string()
          .required(() => translate('FULL_NAME_IS_REQUIRED_FIELD'))
          .label('Full Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        roleId: Yup.string()
          .notOneOf(['0'], () => translate('ROLE_IS_REQUIRED_FIELD'))
          .required(() => translate('ROLE_IS_REQUIRED_FIELD'))
          .label('Role'),
        clientId: Yup.string()
          .notOneOf(['0'], () => translate('CLIENT_IS_REQUIRED_FIELD'))
          .required(() => translate('CLIENT_IS_REQUIRED_FIELD'))
          .label('Client'),
        officeName: Yup.array()
          .required(() => translate('OFFICE_IS_REQUIRED_FIELD'))
          .min(1, () => translate('OFFICE_IS_REQUIRED_FIELD')),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        values.officeId = selectedOffice.value;
        values.mobile = '964' + values.mobile;
        delete values.officeName;
        await store.dispatch(Actions.CREATE_CLIENT_USER, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_USER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'users-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('userCreating'), [
          translate('members'),
        ]);
      });

      await store.dispatch(Actions.GET_ALL_CLIENTS, { limit: 50 });
      await store.dispatch(Actions.GET_ALL_ROLES, { limit: 50 });
      const offices = computed(() => store.getters.gwOfficesList);
      const officesTitle = () => {
        return offices.value.map((r) => {
          return { title: r.titleTranslate, id: r.id };
        });
      };
      return {
        clients: computed(() => store.getters.allClientsList),
        roles: computed(() => store.getters.rolesList),
        onSubmitCreate,
        officesTitle,
        selectedOffice,
        selectedRole,
        selectedClient,
        selectedItem,
        submitButton,
        translate,
        schema,
        userSchema,
        goBack,
        can,
      };
    },
  });
